import { createContext, Dispatch } from "react";
import { GlobalContextType } from "./model";
import { Action } from "./reducer";

export const initialContext: GlobalContextType = {};

export const globalContext = createContext<{
  state: GlobalContextType;
  dispatch: Dispatch<Action>;
}>({ state: initialContext, dispatch: () => ({}) });
export const GlobalProvider = globalContext.Provider;
