import { useEffect, useState } from "react";
import { MenuInfo } from 'rc-menu/lib/interface';
import { DownOutlined } from '@ant-design/icons';


import {
    Input,
    Layout,
    Form,
    Button,
    Table,
    PageHeader,
    Row,
    Col,
    InputNumber,
    Dropdown,
    Menu,
} from "antd";

import {
    updateResource,
    useDataResource,
} from "src/api";

const { Content } = Layout;
const { Search } = Input;

export interface CustomShopDetails {
    shop_id: number;
    custom_discount: number;
    filter_app_ids: {
        filter_type: string;
        app_ids: number[];
    };

}


export const CustomShopDetailsCRUD: React.FC = () => {


    const [selected, setSelected] = useState<CustomShopDetails>();
    const [saving, setSaving] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [selectedItem, setSelectedItem] = useState<{ [shop_id: number]: string }>({});

    const { data, mutate, isLoading } = useDataResource<CustomShopDetails[]>("custom-shop-details/all");
    const [form] = Form.useForm<CustomShopDetails>();

    const parseAppIds = (app_ids: number[]) => {
        if (typeof app_ids === 'string') {
            const ids = app_ids as string
            const excludeAppIdsList = ids.length > 0 ? ids.split(',').map((id: string) => parseInt(id.trim(), 10)) : [];
            return excludeAppIdsList;
        }
        return app_ids
    }

    useEffect(() => {
        selected && form.setFieldsValue(selected);
    }, [selected]);

    useEffect(() => {
        if (!isLoading && data) {
            const defaultFilterTypes: { [shop_id: number]: string } = {};
            data.forEach((shop) => {
                if (shop.filter_app_ids) {
                    defaultFilterTypes[shop.shop_id] = shop.filter_app_ids.filter_type;
                }
            });
            setSelectedItem(defaultFilterTypes);
        }
    }, [isLoading]);

    const saveShopInfo = async (shop_info: CustomShopDetails) => {
        setSaving(true);
        let response = undefined;

        shop_info.filter_app_ids.app_ids = parseAppIds(shop_info.filter_app_ids.app_ids)
        shop_info.filter_app_ids.filter_type = selectedItem[shop_info.shop_id]

        response = await updateResource<CustomShopDetails, CustomShopDetails>(
            `custom-shop-details/${shop_info.shop_id}`,
            "PATCH",
            shop_info
        );

        if (response) {
            mutate();
        }
        setSaving(false);
    };
    const handleMenuClick = (e: MenuInfo, shop_id: number) => {
        setFilterTypeForShop(shop_id, e.key);

    };
    const setFilterTypeForShop = (shop_id: number, filter_type: string) => {
        setSelectedItem((prev_filter_types) => ({
            ...prev_filter_types,
            [shop_id]: filter_type,
        }));
    };

    const menu = (shop_id: number) => (
        <Menu onClick={(e) => handleMenuClick(e, shop_id)}>
            <Menu.Item key="none">none</Menu.Item>
            <Menu.Item key="include">include</Menu.Item>
            <Menu.Item key="exclude">exclude</Menu.Item>
        </Menu>
    );

    return (

        <Layout>
            <PageHeader title="Custom Shop Details" />

            <Content style={{ padding: 24 }}>
                <Row>
                    <Col span={20}>-
                        <Search
                            placeholder="Search by shop_id"
                            onSearch={setSearchValue}
                            style={{ width: 200 }}
                        />
                    </Col>

                </Row>

                <div style={{ paddingBottom: 32 }} />
                <Table
                    style={{ cursor: "pointer" }}
                    onRow={(record) => {
                        return {
                            onClick: () => setSelected(record),
                        };
                    }}
                    pagination={{ pageSize: 10 }}
                    dataSource={data
                        ?.filter((d) => !searchValue || d.shop_id.toString().includes(searchValue))
                    }
                    columns={[
                        {
                            title: "Shop id",
                            dataIndex: "shop_id",
                            key: "shop_id",
                        },
                        {
                            title: "Custom discount",
                            dataIndex: "custom_discount",
                            key: "custom_discount",
                        },
                        {
                            title: "App ids",
                            dataIndex: ["filter_app_ids", "app_ids"],
                            key: "app_ids",
                            render: (ids) => ids.join(),

                        },
                    ]}
                />

                {selected && (
                    <>
                        <Form
                            labelCol={{ span: 3 }}
                            wrapperCol={{ span: 10 }}
                            form={form}
                            name="shop-info-form"
                            layout="horizontal"
                            onFinish={(v) => saveShopInfo(v)}
                            initialValues={selected}
                            labelAlign="left"
                        >
                            <Form.Item
                                name="shop_id"
                                label="Shop id"
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="custom_discount"
                                label="Custom discount"
                                rules={[{ required: false }]}
                            >
                                <InputNumber />
                            </Form.Item>

                            <Form.Item
                                name={['filter_app_ids', 'app_ids']}
                                label="App ids"
                                rules={[{ required: false }]}
                            >
                                <Input />
                            </Form.Item>

                            <Row gutter={10}>
                                <Dropdown.Button type="primary" overlay={menu(selected?.shop_id)} icon={<DownOutlined />}
                                >
                                    <button className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                                        {selectedItem[selected.shop_id] ? `Selected filter: ${selectedItem[selected.shop_id]}` : 'Select filter type'}
                                    </button>
                                </Dropdown.Button>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ marginLeft: 16 }}
                                        loading={saving}
                                    >
                                        Save
                                    </Button>
                                </Form.Item>
                            </Row>

                        </Form>
                    </>
                )}
            </Content>
        </Layout >

    );
};
