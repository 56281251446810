import React from "react";
import { GlobalContextProvider } from "src/context/Provider";
import AppRoutes from "src/routes";
import { GlobalStyle } from "src/styles/globalStyles";
import { defaultTheme } from "src/styles/themes";
import { ThemeProvider } from "styled-components";

export const Root: React.FC = () => {
  return (
    <div data-testid="root">
      <GlobalStyle />
      <GlobalContextProvider>
        <ThemeProvider theme={defaultTheme}>
          <AppRoutes />
        </ThemeProvider>
      </GlobalContextProvider>
    </div>
  );
};
