import { GlobalContextType } from "./model";

export type Action = { type: "init" };
export type Reducer = (
  prevState: GlobalContextType,
  action: Action
) => GlobalContextType;

export const reducer: Reducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
