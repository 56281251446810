import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    body {
        overflow-x: hidden;
        font-weight: 400;
        font-family: 'Zen Kaku Gothic Antique', sans-serif;
        
        font-size: 14px;
        line-height: 1.4;
        margin: 0;
    }

    a {
        text-decoration: none;
    }

    h1{
        font-size: 50px;
    }

    svg {
        vertical-align: middle;
        transform: unset;
    }

    /* * {
        box-sizing: border-box;
    } */

    svg text::selection { background: none; }


    button {
        border: none;
        background: none;
        :focus{
            outline: 0;
        }
    }

    textarea {
        border: none;
        resize: none;
    }
`;
