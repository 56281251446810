import React, { ReactNode, useReducer } from "react";

import { GlobalProvider, initialContext } from ".";
import { reducer } from "./reducer";

export const GlobalContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialContext);
  return (
    <GlobalProvider value={{ state, dispatch }}>{children}</GlobalProvider>
  );
};
