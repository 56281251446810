import React, { PropsWithChildren, useState } from "react";
import type { MenuProps } from "antd";
import { Layout, Menu, Typography, Space } from "antd";

import { ShopOutlined, ExperimentOutlined, ToolOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router";

import logo from "src/assets/logo.svg";

const { Content, Footer, Sider } = Layout;
const { Title } = Typography;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Competitors", "competitors", <ShopOutlined />, [
    getItem("Edit", "edit"),
  ]),
  getItem("Next steps", "next-steps", <ExperimentOutlined />, [
    getItem("Blogs", "blogs"),
  ]),
  getItem("Shop Custom Details", "custom-shop-details", <ToolOutlined />, [
    getItem("Edit", "edit"),
  ]),
];

const AppLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const onClick: MenuProps["onClick"] = async (e) => {
    const path = e.keyPath.reverse().join("/");
    navigate(`/${path}`);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Space style={{ marginTop: 16 }}>
          <img
            src={logo}
            alt="Logo"
            style={{ width: 32, height: 32, marginLeft: 24 }}
          />
          {!collapsed && (
            <Title
              level={5}
              style={{ color: "white", marginLeft: 16, marginTop: 8 }}
            >
              Lebesgue Admin
            </Title>
          )}
        </Space>

        <Menu theme="dark" mode="inline" items={items} onClick={onClick} />
      </Sider>
      <Layout className="site-layout">
        <Content style={{ margin: "0 16px" }}>{children}</Content>
        <Footer style={{ textAlign: "center" }}>Lebesgue Admin ©2023</Footer>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
