import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { CompetitorsEdit } from "./modules/Competitors";
import { IndexPage } from "./modules/Index";
import AppLayout from "./modules/Layout";
import { BlogsCRUD } from "./modules/Blogs";
import { CustomShopDetailsCRUD } from "./modules/CustomShopDetails";
const AppRoutes: React.FC = () => (
  <Router>
    <AppLayout>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/competitors/edit" element={<CompetitorsEdit />} />
        <Route path="/next-steps/blogs" element={<BlogsCRUD />} />
        <Route path="/custom-shop-details/edit" element={<CustomShopDetailsCRUD />} />
      </Routes>
    </AppLayout>
  </Router>
);

export default AppRoutes;
