import useSWR from "swr";
import queryString from "query-string";
import { Buffer } from "buffer";

export const API_BASE_URL = `${process.env.REACT_APP_API_HOST}/api/internal/`;
const username = process.env.REACT_APP_AUTH_USER;
const password = process.env.REACT_APP_AUTH_PASSWORD;

export const headers: HeadersInit = {
  "Content-Type": "application/json",
  Authorization: `Basic ${Buffer.from(username + ":" + password).toString(
    "base64"
  )}`,
};

export const deleteResource = async <R>(url: string): Promise<R> => {
  const response = await fetch(`${API_BASE_URL}${url}`, {
    headers: getHeaders(),
    method: "DELETE",
  });

  return checkResponse(response);
};

/**
 * Generic function for updating resources on server.
 *
 * @remarks
 * It accepts two types D for body data type and R for response type
 *
 * @param url - Url of resource
 * @param method - POST | PATCH | PUT
 * @param data - Body data type of type D
 * @returns Response of type R
 *
 */
export const updateResource = async <D, R>(
  url: string,
  method: "POST" | "PATCH" | "PUT" | "DELETE",
  data: D
): Promise<R> => {
  const response = await fetch(`${API_BASE_URL}${url}`, {
    headers: getHeaders(),
    body: JSON.stringify(data),
    method: method,
  });

  return checkResponse(response);
};

export const postFormData = async <R>(
  url: string,
  data: FormData
): Promise<R> => {
  const response = await fetch(`${API_BASE_URL}${url}`, {
    headers: {
      Authorization: `Basic ${Buffer.from(username + ":" + password).toString(
        "base64"
      )}`,
    },
    body: data,
    method: "POST",
  });

  return checkResponse(response);
};

const sanitizeParams = (
  params?: Record<string, string | string[] | undefined>
): Record<string, string> => {
  let sParams: Record<string, string> = {};

  if (params) {
    Object.keys(params)
      .filter((k) => params[k])
      .forEach((k) => (sParams[k] = params[k] as string));
  }

  return sParams;
};

export const getResource = async <R>(
  url: string,
  params?: Record<string, string | undefined>
): Promise<R> => {
  const qp = new URLSearchParams(sanitizeParams(params));

  // Create a stable key for SWR
  qp.sort();
  const qs = qp.toString();

  const response = await fetch(
    `${API_BASE_URL}${url}${qs.length > 0 ? `?${qs}` : ""}`,
    {
      headers: getHeaders(),
      redirect: "follow",
    }
  );

  return checkResponse(response);
};

const checkResponse = async <R>(response: Response): Promise<R> => {
  if (response.status >= 200 && response.status < 400) return response.json();

  throw Error();
};

const getHeaders = (): HeadersInit => {
  let h = { ...headers };

  return h;
};

export const fetcher = async <R>(url: string): Promise<R> => {
  const response = await fetch(url, {
    headers: getHeaders(),
  });

  if (!response.ok) {
  }

  return response.json();
};

export const useDataResource = <R>(
  url: string,
  params?: Record<string, string | undefined>
) => {
  // const qp = new URLSearchParams(sanitizeParams(params));
  // // Create a stable key for SWR
  // qp.sort();
  // const qs = qp.toString();

  const qp = queryString.stringify(params || {});

  const { data, error, mutate } = useSWR<R>(
    `${API_BASE_URL}${url}${qp.length > 0 ? `?${qp}` : ""}`,
    fetcher,
    { shouldRetryOnError: false }
  );

  return {
    data: data,
    isLoading: data === undefined && !error,
    error: error,
    mutate: mutate,
  };
};
