import { useEffect, useRef, useState } from "react";
import {
  Input,
  Layout,
  Form,
  Button,
  Table,
  PageHeader,
  Typography,
  Row,
  Col,
  Tag,
} from "antd";
import type { InputRef } from "antd";

import {
  updateResource,
  useDataResource,
} from "src/api";
import { PlusOutline } from "styled-icons/evaicons-outline";

const { Content } = Layout;
const { Title } = Typography;
const { TextArea, Search } = Input;

export interface BlogPost {
  id?: number;
  title: string;
  link: string;
  body_short: string;
  body_long: string;
  tags: { tag_keys: string[] };
}

export const BlogsCRUD: React.FC = () => {
  const [selected, setSelected] = useState<BlogPost>();
  const [saving, setSaving] = useState(false);

  const [tags, setTags] = useState<string[]>([]);
  const [tagVisible, setTagVisible] = useState(false);
  const [tagValue, setTagValue] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [create, setCreate] = useState(false);

  const inputRef = useRef<InputRef>(null);

  const { data, mutate } = useDataResource<BlogPost[]>("blogs/all");

  const [form] = Form.useForm<BlogPost>();

  useEffect(() => {
    selected && form.setFieldsValue(selected);
    setTags(selected?.tags.tag_keys || []);
  }, [selected]);

  const saveCompetitor = async (c: BlogPost) => {
    setSaving(true);
    c.tags = { tag_keys: tags };
    let response = undefined;

    if (create) {
      response = await updateResource<BlogPost, BlogPost>(
        `blogs/create`,
        "POST",
        c
      );
    } else {
      response = await updateResource<BlogPost, BlogPost>(
        `blogs/${c.id}`,
        "PATCH",
        c
      );
    }

    if (response) {
      mutate();
    }
    if (create) {
      setSelected(undefined);
      setCreate(false);
    }
    setSaving(false);
  };

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setTagVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (tagValue && tags.indexOf(tagValue) === -1) {
      setTags([...tags, tagValue]);
    }
    setTagVisible(false);
    setTagValue("");
  };

  const tagPlusStyle = {
    background: "#fff",
    borderStyle: "dashed",
  };

  return (
    <Layout>
      <PageHeader title="Blog posts" subTitle="CRUD blog posts" />

      <Content style={{ padding: 24 }}>
        <Row>
          <Col span={20}>
            <Search
              placeholder="Search blogs"
              onSearch={setSearchValue}
              style={{ width: 200 }}
            />
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              style={{ marginLeft: 16 }}
              onClick={() => {
                setSelected({
                  title: "",
                  body_long: "",
                  body_short: "",
                  link: "",
                  tags: { tag_keys: [] },
                });
                setCreate(true);
              }}
            >
              Create new blog post
            </Button>
          </Col>
        </Row>

        <div style={{ paddingBottom: 32 }} />
        <Table
          style={{ cursor: "pointer" }}
          onRow={(record) => {
            return {
              onClick: () => setSelected(record),
            };
          }}
          pagination={{ pageSize: 10 }}
          dataSource={data?.filter((d) =>
            d.title.toLowerCase().startsWith(searchValue.toLowerCase())
          )}
          columns={[
            {
              title: "Id",
              dataIndex: "id",
              key: "id",
              width: 150,
            },
            {
              title: "Title",
              dataIndex: "title",
              key: "title",
            },
          ]}
        />

        {selected && (
          <>
            <Row>
              <Col span={16}>
                <Title level={4}>
                  {create ? "Create new blog post" : `Blog post ${selected.id}`}
                </Title>

                <Form
                  labelCol={{ span: 3 }}
                  wrapperCol={{ span: 20 }}
                  form={form}
                  name="competitor-form"
                  layout="horizontal"
                  onFinish={(v) => saveCompetitor(v)}
                  initialValues={selected}
                >
                  {!create && (
                    <Form.Item name="id" label="Id">
                      <Input disabled />
                    </Form.Item>
                  )}
                  <Form.Item
                    name="title"
                    label="Title"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="link"
                    label="Link"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="body_short"
                    label="Body short"
                    rules={[{ required: true }]}
                  >
                    <TextArea
                      showCount
                      maxLength={100}
                      style={{ height: 120, resize: "none" }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="body_long"
                    label="Body long"
                    rules={[{ required: true }]}
                  >
                    <TextArea
                      showCount
                      maxLength={1100}
                      style={{ height: 260, resize: "vertical" }}
                    />
                  </Form.Item>

                  <Form.Item label="Tags">
                    {tags.map((tag, i) => (
                      <Tag key={i} onClose={() => handleClose(tag)} closable>
                        {tag}
                      </Tag>
                    ))}

                    {tagVisible ? (
                      <Input
                        ref={inputRef}
                        type="text"
                        size="small"
                        style={{ width: 78 }}
                        value={tagValue}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                      />
                    ) : (
                      <Tag onClick={showInput} style={tagPlusStyle}>
                        <PlusOutline height={16} /> New Tag
                      </Tag>
                    )}
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: 16 }}
                      loading={saving}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </Content>
    </Layout>
  );
};
